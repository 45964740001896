<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="Saisie" name="saisie" v-if="permissions['inventaire'][0].includes('saisie') || permissions['etat'][0].includes('validation')">
        <!-- <EtatJournalierComponent /> -->
    </el-tab-pane>
    <el-tab-pane label="Consulation" name="consult" v-if="permissions['inventaire'][0].includes('consultation')">
    
    </el-tab-pane>
  </el-tabs>
</template>
<script>

    // Utilities
    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import axios from 'axios';


    export default {

        components: {
        },

        props : ['permissions'],

        data() {
            return { 
                activeName: 'saisie',
                saisie : 1,
                consult : 1
            };
        },
        methods: {

            handleClick(tab, event) {
                NProgress.start();
                console.log(tab, event);
                NProgress.done();
            },

        },

        created () {
            // this.loadFilter();
            // this.loadAcces();
        }
    };
</script>